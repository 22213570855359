import Swiper from "swiper/bundle";
import "swiper/css/bundle";

document.addEventListener('DOMContentLoaded', function () {
    const parentContainers = document.querySelectorAll('.elementor-element.e-con-boxed');

    parentContainers.forEach(parentContainer => {
        const testimonials = parentContainer.querySelectorAll('.elementor-widget-testimonial');

        // Check if testimonials exist
        if (testimonials.length === 0) return;

        // Create Swiper structure
        const swiperContainer = document.createElement('div');
        swiperContainer.classList.add('swiper-container');

        const swiperWrapper = document.createElement('div');
        swiperWrapper.classList.add('swiper-wrapper');

        testimonials.forEach(testimonial => {
            const swiperSlide = document.createElement('div');
            swiperSlide.classList.add('swiper-slide');
            swiperSlide.appendChild(testimonial.cloneNode(true));
            swiperWrapper.appendChild(swiperSlide);
        });

        parentContainer.innerHTML = ''; // Clear existing testimonials

        swiperContainer.appendChild(swiperWrapper);
        parentContainer.appendChild(swiperContainer);

        const nextButton = document.createElement('div');
        nextButton.classList.add('swiper-button-next');
        const prevButton = document.createElement('div');
        prevButton.classList.add('swiper-button-prev');

        swiperContainer.appendChild(nextButton);
        swiperContainer.appendChild(prevButton);

        // Initialize Swiper
        const swiper = new Swiper(swiperContainer, {
            autoHeight: true,
            loop: false,
            slidesPerView: 1,
            speed: 550,
            navigation: {
                nextEl: nextButton,
                prevEl: prevButton,
            }
        });

        // Remove original testimonials from the DOM after cloning
        testimonials.forEach(testimonial => {
            testimonial.remove();
        });
    });
});